// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.menu_detaile_wrapper {
  width: 80%;
  display: flex;
  flex-direction: column;
}

.menu_detaile_menu_positions {
  align-self: center;
  margin-top: 30px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 0.5fr));
  grid-column-gap: 20px;
  width: 100%;
}

@media (max-width: 770px) {
  .menu_detaile_wrapper {
    width: 100%;
  }
  .menu_detaile_menu_positions {
    width: 95%;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  }
}`, "",{"version":3,"sources":["webpack://./src/styles/menu_detaile.scss"],"names":[],"mappings":"AAAA;EACI,UAAA;EACA,aAAA;EACA,sBAAA;AACJ;;AACA;EACI,kBAAA;EACA,gBAAA;EACA,aAAA;EACA,6DAAA;EACA,qBAAA;EACA,WAAA;AAEJ;;AAOA;EACI;IACI,WAAA;EAJN;EAME;IACI,UAAA;IACA,2DAAA;EAJN;AACF","sourcesContent":[".menu_detaile_wrapper{\n    width: 80%;\n    display: flex;\n    flex-direction: column;\n}\n.menu_detaile_menu_positions{\n    align-self: center;\n    margin-top: 30px;\n    display: grid;\n    grid-template-columns: repeat(auto-fit, minmax(300px, 0.5fr));\n    grid-column-gap: 20px;\n    width: 100%;\n}\n\n@media (min-width:1280px){\n\n}\n@media (max-width:1280px){\n    \n}\n@media (max-width:770px){\n    .menu_detaile_wrapper{\n        width: 100%;\n    }\n    .menu_detaile_menu_positions{\n        width: 95%;\n        grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));\n    }\n}\n@media (max-width:550px){\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
