// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.order_card_wrapper {
  margin-top: 20px;
  height: 70px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #323131;
  border-radius: 50px;
  border: 1px solid white;
  font-size: 24px;
  color: #909090;
}

.order_card_wrapper:hover {
  border: 1px solid #F5DB51;
  color: #F5DB51;
}

.order_card_slot.regular {
  color: #909090;
}

.order_card_slot.yellow {
  color: #F5DB51;
}

.order_card_slot.red {
  color: #F15A5A;
}

.order_card_slot.green {
  color: #86F584;
}

.order_card_slot.id {
  margin-left: 0px;
  width: 80px;
  text-align: center;
}

.order_card_slot.date {
  width: 120px;
  text-align: center;
}

.order_card_slot.state {
  width: 100px;
  text-align: center;
}

.order_card_slot.total_price {
  width: 120px;
  text-align: center;
}

@media (max-width: 1280px) {
  .order_card_wrapper {
    height: 50px;
    font-size: 18px;
  }
}
@media (max-width: 769px) {
  .order_card_wrapper {
    height: 40px;
    margin-top: 15px;
    font-size: 16px;
  }
}`, "",{"version":3,"sources":["webpack://./src/styles/order_card.scss"],"names":[],"mappings":"AAAA;EACI,gBAAA;EACA,YAAA;EACA,WAAA;EACA,aAAA;EACA,8BAAA;EACA,mBAAA;EACA,yBAAA;EACA,mBAAA;EACA,uBAAA;EACA,eAAA;EACA,cAAA;AACJ;;AACA;EACI,yBAAA;EACA,cAAA;AAEJ;;AACA;EACI,cAAA;AAEJ;;AAAA;EACI,cAAA;AAGJ;;AADA;EACI,cAAA;AAIJ;;AAFA;EACI,cAAA;AAKJ;;AAFA;EACI,gBAAA;EACA,WAAA;EACA,kBAAA;AAKJ;;AAHA;EACI,YAAA;EACA,kBAAA;AAMJ;;AAJA;EACI,YAAA;EACA,kBAAA;AAOJ;;AALA;EACI,YAAA;EACA,kBAAA;AAQJ;;AAAA;EACI;IACI,YAAA;IACA,eAAA;EAGN;AACF;AAAA;EACI;IACI,YAAA;IACA,gBAAA;IACA,eAAA;EAEN;AACF","sourcesContent":[".order_card_wrapper{\n    margin-top: 20px;\n    height: 70px;    \n    width: 100%;\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    background-color: #323131;\n    border-radius: 50px;\n    border:1px solid white;\n    font-size: 24px;\n    color: #909090;\n}\n.order_card_wrapper:hover{\n    border:1px solid #F5DB51;\n    color: #F5DB51;\n}\n\n.order_card_slot.regular{\n    color: #909090;\n}\n.order_card_slot.yellow{\n    color: #F5DB51;\n}\n.order_card_slot.red{\n    color: #F15A5A;\n}\n.order_card_slot.green{\n    color: #86F584;\n}\n\n.order_card_slot.id{\n    margin-left: 0px;\n    width: 80px;\n    text-align: center;\n}\n.order_card_slot.date{\n    width: 120px;\n    text-align: center;\n}\n.order_card_slot.state{\n    width: 100px;\n    text-align: center;\n}\n.order_card_slot.total_price{\n    width: 120px;\n    text-align: center;\n}\n\n\n@media (min-width:1280px){\n\n}\n\n@media (max-width:1280px){\n    .order_card_wrapper{\n        height: 50px; \n        font-size: 18px;\n    }\n}\n\n@media (max-width:769px){\n    .order_card_wrapper{\n        height: 40px; \n        margin-top: 15px;\n        font-size: 16px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
