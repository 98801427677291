// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.personal-accaunt_wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.personal-account_message {
  padding: 30px;
  color: whitesmoke;
  font-size: 24px;
  border-radius: 10px;
}`, "",{"version":3,"sources":["webpack://./src/styles/personal_account.scss"],"names":[],"mappings":"AAAA;EACI,WAAA;EACA,YAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;AACJ;;AACA;EACI,aAAA;EACA,iBAAA;EACA,eAAA;EAEA,mBAAA;AACJ","sourcesContent":[".personal-accaunt_wrapper{\n    width: 100%;\n    height: 100%;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n}\n.personal-account_message{\n    padding: 30px;\n    color:whitesmoke;\n    font-size: 24px;\n    // border: 1px solid #909090;\n    border-radius: 10px;\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
