// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.popup-content {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}

.popup-button-row {
  margin-bottom: 20px;
}`, "",{"version":3,"sources":["webpack://./src/styles/popup_add_menu_place_list.scss"],"names":[],"mappings":"AAAA;EACI,WAAA;EACA,aAAA;EACA,sBAAA;EACA,mBAAA;EAGA,mBAAA;AADJ;;AAGA;EACI,mBAAA;AAAJ","sourcesContent":[".popup-content{\n    width: 100%;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    // height: 150px;\n    // justify-content: space-around;\n    margin-bottom: 20px;\n}\n.popup-button-row{\n    margin-bottom: 20px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
