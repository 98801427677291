// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.popup_mobile_content.delete_popup {
  display: flex;
  position: relative;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  width: 30%;
  height: auto;
  background-color: #222;
  border-radius: 10px;
  border: 1px solid #909090;
}

.popup_title.delete_popup {
  line-height: 29px;
  color: whitesmoke;
  position: relative;
}

.popup_delete_button_line {
  margin-top: 30px;
  margin-bottom: 20px;
  display: flex;
  width: 80%;
  justify-content: space-between;
}
.popup_delete_button_line .popup_delete_button_wrpper {
  width: 40%;
}
.popup_delete_button_line .popup_delete_button_wrpper .button {
  width: 100%;
}

.popup_delete_masssege {
  text-align: center;
  color: #909090;
  font-size: 24px;
}

@media (max-width: 1280px) {
  .popup_mobile_content.delete_popup {
    width: 40%;
  }
}
@media (max-width: 990px) {
  .popup_mobile_content.delete_popup {
    width: 60%;
  }
}
@media (max-width: 769px) {
  .popup_mobile_content.delete_popup {
    width: 75%;
  }
}
@media (max-width: 550px) {
  .popup_mobile_content.delete_popup {
    width: 90%;
  }
}`, "",{"version":3,"sources":["webpack://./src/styles/popup_delete.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,kBAAA;EACA,8BAAA;EACA,mBAAA;EACA,sBAAA;EACA,UAAA;EACA,YAAA;EACA,sBAAA;EACA,mBAAA;EACA,yBAAA;AACJ;;AAEA;EACI,iBAAA;EACA,iBAAA;EACA,kBAAA;AACJ;;AAEA;EACI,gBAAA;EACA,mBAAA;EACA,aAAA;EACA,UAAA;EACA,8BAAA;AACJ;AAAI;EACI,UAAA;AAER;AADQ;EACI,WAAA;AAGZ;;AAGA;EAEI,kBAAA;EACA,cAAA;EACA,eAAA;AADJ;;AAMA;EACI;IACI,UAAA;EAHN;AACF;AAMA;EACI;IACI,UAAA;EAJN;AACF;AAUA;EACI;IACI,UAAA;EARN;AACF;AAcA;EACI;IACI,UAAA;EAZN;AACF","sourcesContent":[".popup_mobile_content.delete_popup{\n    display: flex;\n    position: relative;\n    justify-content: space-between;\n    align-items: center;\n    flex-direction: column;\n    width:30%;\n    height: auto;\n    background-color: #222;\n    border-radius: 10px;\n    border: 1px solid #909090;\n}\n\n.popup_title.delete_popup{\n    line-height: 29px;\n    color: whitesmoke;\n    position: relative;\n}\n\n.popup_delete_button_line{\n    margin-top: 30px;\n    margin-bottom: 20px;\n    display: flex;\n    width: 80%;\n    justify-content: space-between;\n    .popup_delete_button_wrpper{\n        width: 40%;\n        .button{\n            width: 100%;\n    \n        }\n    }\n    \n}\n.popup_delete_masssege{\n    // margin-top: 20px;\n    text-align: center;\n    color: #909090;\n    font-size: 24px;\n}\n\n\n\n@media (max-width:1280px){\n    .popup_mobile_content.delete_popup{\n        width: 40%;\n    }\n}\n\n@media (max-width:990px){\n    .popup_mobile_content.delete_popup{\n        width: 60%;\n    }\n\n}\n\n\n\n@media (max-width:769px){\n    .popup_mobile_content.delete_popup{\n        width: 75%;\n    }\n\n\n}\n\n\n@media (max-width:550px){\n    .popup_mobile_content.delete_popup{\n        width: 90%;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
