// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.big-button {
  font-size: 2em;
  padding: 1em 2em;
  height: 200px;
  width: 200px;
  background-color: red;
  color: white;
  border: none;
  border-radius: 200px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

.big-button:hover {
  transform: scale(1.1);
}

.big-button.playing {
  background-color: green;
}`, "",{"version":3,"sources":["webpack://./src/styles/sound_button.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,aAAA;AACJ;;AAEE;EACE,cAAA;EACA,gBAAA;EACA,aAAA;EACA,YAAA;EACA,qBAAA;EACA,YAAA;EACA,YAAA;EACA,oBAAA;EACA,eAAA;EACA,gCAAA;AACJ;;AAEE;EACE,qBAAA;AACJ;;AAEE;EACE,uBAAA;AACJ","sourcesContent":[".container {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    height: 100vh;\n  }\n  \n  .big-button {\n    font-size: 2em;\n    padding: 1em 2em;\n    height: 200px;\n    width: 200px;\n    background-color: red;\n    color: white;\n    border: none;\n    border-radius: 200px;\n    cursor: pointer;\n    transition: all 0.2s ease-in-out;\n  }\n  \n  .big-button:hover {\n    transform: scale(1.1);\n  }\n  \n  .big-button.playing {\n    background-color: green;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
