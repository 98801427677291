// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.order_list_table_head_wrapper {
  margin-top: 20px;
  height: 50px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #222;
  font-size: 28px;
  color: whitesmoke;
}

.order_list_table_head_slot {
  text-align: center;
}

.order_list_table_head_slot.id {
  margin-left: 10px;
  width: 50px;
}

.order_list_table_head_slot.date {
  width: 120px;
}

.order_list_table_head_slot.state {
  width: 100px;
}

.order_list_table_head_slot.total_price {
  width: 150px;
}

@media (max-width: 1280px) {
  .order_list_table_head_wrapper {
    font-size: 20px;
  }
}
@media (max-width: 769px) {
  .order_list_table_head_wrapper {
    font-size: 18px;
    height: 30px;
  }
  .order_list_table_head_slot {
    text-align: center;
  }
  .order_list_table_head_slot.id {
    margin-left: 10px;
    width: 50px;
  }
  .order_list_table_head_slot.date {
    width: 120px;
  }
  .order_list_table_head_slot.state {
    width: 100px;
  }
  .order_list_table_head_slot.total_price {
    width: 150px;
  }
}`, "",{"version":3,"sources":["webpack://./src/styles/order_list_table_head.scss"],"names":[],"mappings":"AAAA;EACI,gBAAA;EACA,YAAA;EACA,WAAA;EACA,aAAA;EACA,8BAAA;EACA,mBAAA;EACA,sBAAA;EACA,eAAA;EACA,iBAAA;AACJ;;AAEA;EACI,kBAAA;AACJ;;AAGA;EACI,iBAAA;EACA,WAAA;AAAJ;;AAEA;EACI,YAAA;AACJ;;AAEA;EACI,YAAA;AACJ;;AAEA;EACI,YAAA;AACJ;;AAOA;EACI;IACI,eAAA;EAJN;AACF;AAOA;EACI;IACI,eAAA;IACA,YAAA;EALN;EAOE;IACI,kBAAA;EALN;EASE;IACI,iBAAA;IACA,WAAA;EAPN;EASE;IACI,YAAA;EAPN;EAUE;IACI,YAAA;EARN;EAWE;IACI,YAAA;EATN;AACF","sourcesContent":[".order_list_table_head_wrapper{\n    margin-top: 20px;\n    height: 50px;    \n    width: 100%;\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    background-color: #222;\n    font-size: 28px;\n    color: whitesmoke;\n\n}\n.order_list_table_head_slot{\n    text-align: center;\n    // border-bottom: 1px solid #909090    ;\n\n}\n.order_list_table_head_slot.id{\n    margin-left: 10px;\n    width: 50px;\n}\n.order_list_table_head_slot.date{\n    width: 120px;\n    \n}\n.order_list_table_head_slot.state{\n    width: 100px;\n    \n}\n.order_list_table_head_slot.total_price{\n    width: 150px;\n}\n\n\n@media (min-width:1280px){\n\n}\n\n@media (max-width:1280px){\n    .order_list_table_head_wrapper{\n        font-size: 20px;\n    }\n}\n\n@media (max-width:769px){\n    .order_list_table_head_wrapper{\n        font-size: 18px;\n        height: 30px;  \n    }\n    .order_list_table_head_slot{\n        text-align: center;\n        // border-bottom: 1px solid #909090    ;\n    \n    }\n    .order_list_table_head_slot.id{\n        margin-left: 10px;\n        width: 50px;\n    }\n    .order_list_table_head_slot.date{\n        width: 120px;\n        \n    }\n    .order_list_table_head_slot.state{\n        width: 100px;\n        \n    }\n    .order_list_table_head_slot.total_price{\n        width: 150px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
