// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mobile_wrapper {
  background-color: #222;
}

.mobile_search_wrapper {
  margin-top: 20px;
  width: 80%;
  border-bottom: 1px solid #909090;
  display: flex;
}

.mobile_search_input {
  width: 100%;
  background-color: #222;
  font-size: 18px;
  border-bottom: #222;
}

.mobile_search_icon {
  width: 40px;
  height: 40px;
  right: 0px;
  fill: inherit;
  fill: #909090;
}

.product_table {
  margin-top: 20px;
  width: 80%;
  margin-bottom: 20px;
}

.add_product_btn {
  margin-top: 30px;
}
.add_product_btn .button {
  background-color: #6846F0;
  width: 300px;
}
.add_product_btn .button_label {
  color: white;
}`, "",{"version":3,"sources":["webpack://./src/styles/product_list_mobile.scss"],"names":[],"mappings":"AAAA;EACI,sBAAA;AACJ;;AAEA;EACI,gBAAA;EACA,UAAA;EACA,gCAAA;EACA,aAAA;AACJ;;AACA;EACI,WAAA;EACA,sBAAA;EACA,eAAA;EACA,mBAAA;AAEJ;;AAAA;EACI,WAAA;EACA,YAAA;EACA,UAAA;EACA,aAAA;EACA,aAAA;AAGJ;;AADA;EACI,gBAAA;EACA,UAAA;EACA,mBAAA;AAIJ;;AAAA;EACI,gBAAA;AAGJ;AAFI;EACE,yBAAA;EACA,YAAA;AAIN;AADI;EACI,YAAA;AAGR","sourcesContent":[".mobile_wrapper{\n    background-color: #222;\n}\n\n.mobile_search_wrapper{\n    margin-top: 20px;\n    width: 80%;\n    border-bottom: 1px solid #909090;\n    display: flex;\n}\n.mobile_search_input{\n    width: 100%;\n    background-color: #222;\n    font-size: 18px;\n    border-bottom: #222;\n}\n.mobile_search_icon{\n    width: 40px;\n    height: 40px;   \n    right: 0px;\n    fill: inherit;\n    fill: #909090;\n}\n.product_table{\n    margin-top: 20px;\n    width: 80%;\n    margin-bottom: 20px;\n        \n}\n\n.add_product_btn{\n    margin-top: 30px;\n    .button{\n      background-color: #6846F0; \n      width: 300px;\n      \n    }\n    .button_label{\n        color: white; \n    }\n    \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
