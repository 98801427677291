// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.side_bar_content {
  display: flex;
  flex-direction: column;
  margin-left: 20px;
  border-radius: 10px;
  height: 100%;
  align-items: center;
  box-shadow: 0px 0px 6px 0px #FEFAF0;
}

.side_bar_btn {
  background-color: #222222;
  margin: 0px auto;
  margin-top: 7px;
}

.side_bar_btn:hover .side_bar_icon path {
  fill: inherit;
}
.side_bar_btn:hover .side_bar_icon {
  fill: #FFBF1C;
}`, "",{"version":3,"sources":["webpack://./src/styles/side_bar.scss"],"names":[],"mappings":"AAEA;EACI,aAAA;EACA,sBAAA;EACA,iBAAA;EACA,mBAAA;EACA,YAAA;EACA,mBAAA;EACA,mCAAA;AADJ;;AAKA;EACE,yBAAA;EACA,gBAAA;EACA,eAAA;AAFF;;AAOE;EACE,aAAA;AAJJ;AAME;EACE,aAAA;AAJJ","sourcesContent":["// Side bar\n\n.side_bar_content{\n    display: flex;\n    flex-direction: column;\n    margin-left: 20px;\n    border-radius: 10px;\n    height: 100%;\n    align-items: center;\n    box-shadow: 0px 0px 6px 0px #FEFAF0 ;\n}\n\n\n.side_bar_btn{\n  background-color: #222222;\n  margin: 0px auto;\n  margin-top: 7px;\n}\n\n\n.side_bar_btn:hover{\n  .side_bar_icon path{\n    fill: inherit;\n  }\n  .side_bar_icon{\n    fill: #FFBF1C;\n  }\n \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
