// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.popup_content_mobile.regular-message {
  border: 1px solid #909090;
}

.popup_title.regular-message {
  color: #F15A5A;
}

.popup_yes_now_masssege {
  color: white;
}

.button_set {
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 20px 0px;
}`, "",{"version":3,"sources":["webpack://./src/styles/popup_regular_message.scss"],"names":[],"mappings":"AAAA;EACI,yBAAA;AACJ;;AACA;EACI,cAAA;AAEJ;;AAAA;EACI,YAAA;AAGJ;;AADA;EACI,WAAA;EACA,aAAA;EACA,uBAAA;EACA,gBAAA;AAIJ","sourcesContent":[".popup_content_mobile.regular-message{\n    border: 1px solid #909090;\n}\n.popup_title.regular-message{\n    color: #F15A5A;\n}\n.popup_yes_now_masssege{\n    color: white;\n}\n.button_set{\n    width: 100%;\n    display: flex;\n    justify-content: center;\n    margin: 20px 0px;\n    \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
