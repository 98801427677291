// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.menu_list_wrapper {
  display: flex;
  flex-direction: column;
  width: 80%;
}

.menu_list_cards {
  width: 100%;
  align-self: center;
  margin-top: 30px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 0.5fr));
  grid-column-gap: 20px;
}

@media (max-width: 1280px) {
  .menu_card_wrapper {
    font-size: 18px;
  }
}
@media (max-width: 769px) {
  .menu_list_wrapper {
    width: 90%;
  }
  .menu_list_cards {
    width: 95%;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  }
}
@media (max-width: 550px) {
  .menu_list_wrapper {
    width: 99%;
  }
  .service_button_row.menu_list .button {
    height: 25px;
    width: 140px;
  }
}`, "",{"version":3,"sources":["webpack://./src/styles/menu_list.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,sBAAA;EACA,UAAA;AACJ;;AAMA;EACI,WAAA;EACA,kBAAA;EACA,gBAAA;EACA,aAAA;EACA,6DAAA;EACA,qBAAA;AAHJ;;AASA;EACI;IAEI,eAAA;EAPN;AACF;AASA;EACI;IACI,UAAA;EAPN;EASE;IACI,UAAA;IACA,2DAAA;EAPN;AACF;AASA;EACI;IACI,UAAA;EAPN;EAYM;IACI,YAAA;IACA,YAAA;EAVV;AACF","sourcesContent":[".menu_list_wrapper{\n    display: flex;\n    flex-direction: column;\n    width: 80%;\n    // align-items: center;\n}\n\n\n\n\n.menu_list_cards{\n    width: 100%;\n    align-self: center;\n    margin-top: 30px;\n    display: grid;\n    grid-template-columns: repeat(auto-fit, minmax(300px, 0.5fr));\n    grid-column-gap: 20px;\n}\n\n@media (min-width:1280px){\n\n}\n@media (max-width:1280px){\n    .menu_card_wrapper{\n        // height: 50px; \n        font-size: 18px;\n    }\n}\n@media (max-width:769px){\n    .menu_list_wrapper{\n        width: 90%;\n    }\n    .menu_list_cards{\n        width: 95%;\n        grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));\n    }\n}\n@media (max-width:550px){\n    .menu_list_wrapper{\n        width: 99%;\n    }\n\n    .service_button_row.menu_list{\n        // margin-top: 20px;\n        .button{\n            height: 25px;\n            width: 140px;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
