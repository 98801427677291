// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.popup_mobile_content.upload_photo {
  display: flex;
  position: relative;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  width: 30%;
  height: auto;
  background-color: #222;
  border-radius: 10px;
  border: 1px solid #909090;
}

.popup_title.upload_photo {
  line-height: 29px;
  color: whitesmoke;
  position: relative;
}

.upload_product_photo_button {
  margin: 20px 0px;
  width: 100%;
  display: flex;
  justify-content: center;
}
.upload_product_photo_button .button {
  height: 50px;
  width: 90%;
}

@media (max-width: 1280px) {
  .popup_mobile_content.upload_photo {
    width: 50%;
  }
}
@media (max-width: 990px) {
  .popup_mobile_content.upload_photo {
    width: 60%;
  }
}
@media (max-width: 769px) {
  .popup_mobile_content.upload_photo {
    width: 70%;
  }
}
@media (max-width: 450px) {
  .popup_mobile_content.upload_photo {
    width: 90%;
  }
}`, "",{"version":3,"sources":["webpack://./src/styles/popup_upload_photo.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,kBAAA;EACA,8BAAA;EACA,mBAAA;EACA,sBAAA;EACA,UAAA;EACA,YAAA;EACA,sBAAA;EACA,mBAAA;EACA,yBAAA;AACJ;;AAEA;EACI,iBAAA;EACA,iBAAA;EACA,kBAAA;AACJ;;AAEA;EACI,gBAAA;EACA,WAAA;EACA,aAAA;EACA,uBAAA;AACJ;AAAI;EACI,YAAA;EACA,UAAA;AAER;;AAMA;EACI;IACI,UAAA;EAHN;AACF;AAMA;EACI;IACI,UAAA;EAJN;AACF;AAMA;EACI;IACI,UAAA;EAJN;AACF;AAMA;EACI;IACI,UAAA;EAJN;AACF","sourcesContent":[".popup_mobile_content.upload_photo{\n    display: flex;\n    position: relative;\n    justify-content: space-between;\n    align-items: center;\n    flex-direction: column;\n    width:30%;\n    height: auto;\n    background-color: #222;\n    border-radius: 10px;\n    border: 1px solid #909090;\n}\n\n.popup_title.upload_photo{\n    line-height: 29px;\n    color: whitesmoke;\n    position: relative;\n}\n\n.upload_product_photo_button{\n    margin: 20px 0px;\n    width: 100%;\n    display: flex;\n    justify-content: center;\n    .button{\n        height: 50px;\n        width: 90%;\n    }\n}\n\n\n@media (min-width:1280px){\n\n}\n@media (max-width:1280px){\n    .popup_mobile_content.upload_photo{\n        width: 50%;\n    }\n}\n\n@media (max-width:990px){\n    .popup_mobile_content.upload_photo{\n        width: 60%;\n    }\n}\n@media (max-width:769px){\n    .popup_mobile_content.upload_photo{\n        width: 70%;\n    }\n}\n@media (max-width:450px){\n    .popup_mobile_content.upload_photo{\n        width: 90%;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
