// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mobile_wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #323131;
  width: 100%;
}

.mobile_search_wrapper {
  margin-top: 20px;
  width: 80%;
  border-bottom: 1px solid #909090;
  display: flex;
}

.mobile_search_input {
  width: 100%;
  background-color: #323131;
  font-size: 18px;
}

.mobile_search_icon {
  width: 40px;
  height: 40px;
  right: 0px;
  fill: inherit;
  fill: #909090;
}

.places_table {
  margin-top: 2px;
  padding: 30px;
  height: 100%;
}`, "",{"version":3,"sources":["webpack://./src/styles/place_list_mobile.scss"],"names":[],"mappings":"AAAA;EACQ,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,yBAAA;EACA,WAAA;AACR;;AAGA;EACQ,gBAAA;EACA,UAAA;EACA,gCAAA;EACA,aAAA;AAAR;;AAGA;EACQ,WAAA;EACA,yBAAA;EACA,eAAA;AAAR;;AAGA;EACQ,WAAA;EACA,YAAA;EACA,UAAA;EACA,aAAA;EACA,aAAA;AAAR;;AAEA;EACQ,eAAA;EACA,aAAA;EAKA,YAAA;AAHR","sourcesContent":[".mobile_wrapper{\n        display: flex;\n        flex-direction: column;\n        align-items: center;\n        background-color: #323131;\n        width: 100%;  \n        // height: 100vh;\n        // border-radius: 0px 10px 10px 10px;\n    }\n.mobile_search_wrapper{\n        margin-top: 20px;\n        width: 80%;\n        border-bottom: 1px solid #909090;\n        display: flex;\n        // flex-direction: column;\n    }\n.mobile_search_input{\n        width: 100%;\n        background-color: #323131;\n        font-size: 18px;\n        // border-bottom: #323131;\n    }\n.mobile_search_icon{\n        width: 40px;\n        height: 40px;   \n        right: 0px;\n        fill: inherit;\n        fill: #909090;\n    }\n.places_table{\n        margin-top:2px;\n        padding: 30px;\n        // overflow: scroll;\n        // max-height: 500px;\n        // width: 98%; \n        // background-color: #323131;\n        height: 100%;\n    }\n// .places_table::-webkit-scrollbar {\n//         width: 5px;\n//         height: 0px; \n//         border-radius: 10px;\n//     }\n// .places_table::-webkit-scrollbar-track {\n//         background:#909090;\n//         border-radius: 10px;\n//     }        \n// .places_table::-webkit-scrollbar-thumb {\n//         background:#222222;\n//         border-radius: 8px;\n//         border: 1px solid #323131;\n//     }       \n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
