// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loading_wrapper {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.logo_icon {
  width: 30%;
  height: 30%;
  animation: rotateLogo 2.5s infinite linear;
}

@keyframes rotateLogo {
  0% {
    transform: rotateY(0deg);
  }
  100% {
    transform: rotateY(360deg);
  }
}
.loading_label {
  margin-top: 30px;
  font-size: 26px;
  color: #909090;
}`, "",{"version":3,"sources":["webpack://./src/styles/loading.scss"],"names":[],"mappings":"AAAA;EACI,YAAA;EACA,WAAA;EACA,aAAA;EACA,sBAAA;EACA,uBAAA;EACA,mBAAA;AACJ;;AACA;EACI,UAAA;EACA,WAAA;EACA,0CAAA;AAEJ;;AAAA;EACI;IACI,wBAAA;EAGN;EADE;IACI,0BAAA;EAGN;AACF;AADA;EACI,gBAAA;EACA,eAAA;EACA,cAAA;AAGJ","sourcesContent":[".loading_wrapper{\n    height: 100%;\n    width: 100%;\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n}\n.logo_icon{\n    width: 30%;\n    height: 30%;\n    animation: rotateLogo 2.5s infinite linear;\n}\n@keyframes rotateLogo{\n    0%{\n        transform:  rotateY(0deg);\n    }\n    100%{\n        transform: rotateY(360deg);\n    }\n}\n.loading_label{\n    margin-top: 30px;\n    font-size: 26px;\n    color: #909090;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
