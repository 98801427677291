// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.product_list_wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.mobile_content {
  background-color: #222;
}

.greetings {
  background-color: #323131;
  font-size: 16px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  height: 38px;
  width: 80%;
}

@media (min-width: 1280px) {
  .greetings {
    font-size: 34px;
  }
}
@media (max-width: 1280px) {
  .greetings {
    width: 90%;
    font-size: 24px;
  }
}
@media (min-width: 769px) {
  .mobile_content {
    display: none;
  }
}
@media (max-width: 769px) {
  .greetings {
    width: 100%;
    height: 38px;
    margin-top: 40px;
    background-color: #323131;
    font-size: 20px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
  }
}`, "",{"version":3,"sources":["webpack://./src/styles/product_list.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,sBAAA;EACA,mBAAA;AACJ;;AAEA;EACI,sBAAA;AACJ;;AACA;EACI,yBAAA;EACA,eAAA;EACA,YAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,mBAAA;EACA,YAAA;EACA,UAAA;AAEJ;;AACA;EACI;IACI,eAAA;EAEN;AACF;AACA;EAEI;IACI,UAAA;IACA,eAAA;EAAN;AACF;AAEA;EACI;IACI,aAAA;EAAN;AACF;AAGA;EACI;IACI,WAAA;IACA,YAAA;IACA,gBAAA;IACA,yBAAA;IACA,eAAA;IACA,YAAA;IACA,aAAA;IACA,uBAAA;IACA,mBAAA;IACA,mBAAA;EADN;AACF","sourcesContent":[".product_list_wrapper{\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n}\n\n.mobile_content{\n    background-color: #222;\n}\n.greetings{\n    background-color: #323131;\n    font-size: 16px;\n    color: white;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    border-radius: 10px;\n    height: 38px;\n    width: 80%;\n}\n\n@media (min-width:1280px)  {\n    .greetings{\n        font-size: 34px;\n    }\n   \n}\n@media (max-width:1280px)  {\n    \n    .greetings{\n        width: 90%;\n        font-size: 24px;\n    }\n}\n@media (min-width:769px){\n    .mobile_content{\n        display: none;\n    }\n}\n\n@media(max-width:769px) {\n    .greetings{\n        width: 100%;\n        height: 38px;\n        margin-top: 40px;\n        background-color: #323131;\n        font-size: 20px;\n        color: white;\n        display: flex;\n        justify-content: center;\n        align-items: center;\n        border-radius: 10px;\n        \n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
