// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  background-color: #222;
  height: 100%;
}

.main_wrapper {
  height: 100vh;
}

.wrapper {
  width: 100%;
  height: 100vh;
  height: inherit;
  display: flex;
  flex-direction: column;
}

.title {
  width: 100%;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
}

.content {
  display: flex;
  justify-content: space-between;
  height: 100%;
}

.work_space {
  width: 100%;
  height: 100%;
}

.fatal_error {
  color: #909090;
  font-size: 24px;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}`, "",{"version":3,"sources":["webpack://./src/styles/layout.scss"],"names":[],"mappings":"AAAA;EACI,sBAAA;EACA,YAAA;AACJ;;AACA;EACI,aAAA;AAEJ;;AAAA;EACI,WAAA;EACA,aAAA;EACA,eAAA;EACA,aAAA;EACA,sBAAA;AAGJ;;AADA;EACI,WAAA;EACA,mBAAA;EACA,aAAA;EACA,uBAAA;AAIJ;;AAFA;EACI,aAAA;EACA,8BAAA;EACA,YAAA;AAKJ;;AAFA;EACE,WAAA;EACA,YAAA;AAKF;;AAFA;EACI,cAAA;EACA,eAAA;EACA,YAAA;EACA,WAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,kBAAA;AAKJ","sourcesContent":["body{\n    background-color: #222;\n    height: 100%;\n}\n.main_wrapper{\n    height: 100vh;\n}\n.wrapper{\n    width: 100%;\n    height: 100vh;\n    height: inherit;\n    display: flex;\n    flex-direction: column;\n}\n.title{\n    width: 100%;\n    margin-bottom: 20px;\n    display: flex;\n    justify-content: center;\n}\n.content{\n    display: flex;\n    justify-content: space-between;\n    height: 100%;\n}\n\n.work_space{\n  width: 100%;\n  height: 100%;\n}\n\n.fatal_error{\n    color: #909090; \n    font-size: 24px;  \n    height: 100%;\n    width: 100%; \n    display:flex; \n    justify-content: center; \n    align-items: center;  \n    text-align: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
