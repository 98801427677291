// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.managers_list_content {
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media (max-width: 770px) {
  .managers_list_content {
    width: 100%;
  }
}`, "",{"version":3,"sources":["webpack://./src/styles/managers_list.scss"],"names":[],"mappings":"AAAA;EACI,UAAA;EACA,aAAA;EACA,sBAAA;EACA,mBAAA;AACJ;;AAgBA;EACI;IACI,WAAA;EAbN;AACF","sourcesContent":[".managers_list_content{\n    width: 80%;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n}\n\n\n\n\n\n@media (min-width:1280px){\n\n}\n@media (max-width:1280px){\n\n}\n@media (max-width:990px){\n\n\n}\n@media (max-width:770px){\n    .managers_list_content{\n        width: 100%;\n    }\n\n}\n@media (max-width:550px){\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
