// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.invoice_detaile_batton_row {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 15px;
}
.invoice_detaile_batton_row .button {
  width: 150px;
}

.delete_invoice_button .button {
  background-color: #F15A5A;
  transition: 0.3s;
}
.delete_invoice_button .button .button_label {
  color: black;
}
.delete_invoice_button .button:hover {
  background-color: #cd4a4a;
}
.delete_invoice_button .button:hover .button_label {
  color: #FFBF1C;
}

.update_invoice_button .button {
  background-color: #7D62EA;
  transition: 0.3s;
}
.update_invoice_button .button .button_label {
  color: black;
}
.update_invoice_button .button:hover {
  background-color: #694be2;
}
.update_invoice_button .button:hover .button_label {
  color: #FFBF1C;
}

@media (max-width: 769px) {
  .delete_invoice_button_wrapper {
    display: none;
  }
}
@media (max-width: 450px) {
  .invoice_detaile_batton_row {
    justify-content: space-around;
  }
  .invoice_detaile_batton_row .button {
    width: 150px;
    height: 25px;
  }
}`, "",{"version":3,"sources":["webpack://./src/styles/invoice_detaile_button_rows.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,8BAAA;EACA,WAAA;EACA,gBAAA;AACJ;AAAI;EACI,YAAA;AAER;;AAEI;EACI,yBAAA;EACA,gBAAA;AACR;AAAQ;EACI,YAAA;AAEZ;AACI;EACI,yBAAA;AACR;AAAQ;EACI,cAAA;AAEZ;;AAII;EACI,yBAAA;EACA,gBAAA;AADR;AAEQ;EACI,YAAA;AAAZ;AAGI;EACI,yBAAA;AADR;AAEQ;EACI,cAAA;AAAZ;;AAsBA;EACI;IACI,aAAA;EAnBN;AACF;AAsBA;EACI;IAEI,6BAAA;EArBN;EAsBM;IACI,YAAA;IACA,YAAA;EApBV;AACF","sourcesContent":[".invoice_detaile_batton_row{\n    display: flex;\n    justify-content: space-between;\n    width: 100%;\n    margin-top: 15px;\n    .button{\n        width: 150px;\n    }\n}\n.delete_invoice_button{\n    .button{\n        background-color: #F15A5A;\n        transition: 0.3s;\n        .button_label{\n            color: black;\n        }\n    }\n    .button:hover{\n        background-color: #cd4a4a;\n        .button_label{\n            color:#FFBF1C;\n        }\n    }\n}\n.update_invoice_button{\n  \n    .button{\n        background-color: #7D62EA;\n        transition: 0.3s;\n        .button_label{\n            color: black;\n        }\n    }\n    .button:hover{\n        background-color: #694be2;\n        .button_label{\n            color:#FFBF1C;\n        }\n    }\n}\n\n\n\n@media (max-width:1280px){\n\n}\n\n@media (min-width:990px){\n\n}\n\n@media (max-width:990px){\n\n}\n\n@media (min-width:769px){\n\n}\n@media (max-width:769px){\n    .delete_invoice_button_wrapper{\n        display: none;\n    }\n}\n\n@media (max-width:450px){\n    .invoice_detaile_batton_row{\n        // width: 90%;\n        justify-content: space-around;\n        .button{\n            width: 150px;\n            height: 25px;\n            // margin: 0px,5px;\n        }\n    }\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
