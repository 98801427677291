// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.place-name-ref {
  width: 130px;
  background-color: #222;
  border-bottom: 1px solid #919191;
  color: #919191;
}`, "",{"version":3,"sources":["webpack://./src/styles/popup_create_referral_url.scss"],"names":[],"mappings":"AAAA;EACI,YAAA;EACA,sBAAA;EACA,gCAAA;EACA,cAAA;AACJ","sourcesContent":[".place-name-ref{\n    width: 130px;\n    background-color: #222;\n    border-bottom: 1px solid #919191;\n    color: #919191;\n\n}\n// .popup_mobile_content_add_menu_position{\n//     width: 60%;\n// }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
