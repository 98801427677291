// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.popup_delivered_photo_content {
  width: 650px;
  position: relative;
}

.popup_delivered_photo_sevice_button_wrapper {
  position: absolute;
  right: 20px;
  top: -30px;
}

.popup_delivered_photo_wrapper {
  margin: 0px 40px;
}

.popup_delivered_photo_close_button {
  width: 20px;
  height: 20px;
  cursor: pointer;
}
.popup_delivered_photo_close_button .close_button_icon path {
  fill: #909090;
}
.popup_delivered_photo_close_button .close_button_icon {
  width: 100%;
  height: 100%;
}

.popup_delivered_photo_close_button:hover .close_button_icon path {
  fill: #FFBF1C;
}

@media (max-width: 450px) {
  .popup_delivered_photo_sevice_button_wrapper {
    right: 25px;
    top: -25px;
  }
  .popup_delivered_photo_close_button {
    width: 15px;
    height: 15px;
  }
}`, "",{"version":3,"sources":["webpack://./src/styles/delivered_photo_popup.scss"],"names":[],"mappings":"AAAA;EACI,YAAA;EACA,kBAAA;AACJ;;AAEA;EACI,kBAAA;EACA,WAAA;EACA,UAAA;AACJ;;AACA;EACI,gBAAA;AAEJ;;AAAA;EACI,WAAA;EACA,YAAA;EACA,eAAA;AAGJ;AAFI;EACI,aAAA;AAIR;AADI;EACI,WAAA;EACA,YAAA;AAGR;;AACI;EACI,aAAA;AAER;;AAEA;EACI;IACI,WAAA;IACA,UAAA;EACN;EACE;IACI,WAAA;IACA,YAAA;EACN;AACF","sourcesContent":[".popup_delivered_photo_content{\n    width: 650px;\n    position: relative;\n\n}\n.popup_delivered_photo_sevice_button_wrapper{\n    position: absolute;\n    right: 20px;\n    top:-30px;\n}\n.popup_delivered_photo_wrapper{\n    margin: 0px 40px;\n}\n.popup_delivered_photo_close_button{\n    width: 20px;\n    height: 20px;\n    cursor: pointer;\n    .close_button_icon path{\n        fill: #909090;\n    }\n\n    .close_button_icon{\n        width: 100%;\n        height: 100%;\n    }\n}\n.popup_delivered_photo_close_button:hover{\n    .close_button_icon path{\n        fill: #FFBF1C;\n    }\n}\n\n@media (max-width:450px){\n    .popup_delivered_photo_sevice_button_wrapper{\n        right: 25px;\n        top:-25px;\n    }\n    .popup_delivered_photo_close_button{\n        width: 15px;\n        height: 15px;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
