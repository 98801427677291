// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.button {
  width: 290px;
  height: 30px;
  background: #222222;
  box-shadow: 0px 0px 4px rgba(254, 250, 240, 0.25);
  border-radius: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.button_label {
  font-family: "Fira Mono";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #909090;
}

.button:hover, button:focus {
  box-shadow: 0px 0px 4px rgba(255, 191, 28, 0.25);
}
.button:hover .button_label, button:focus .button_label {
  color: rgb(255, 191, 28);
}
.button:hover .add_button_cross path, button:focus .add_button_cross path {
  fill: inherit;
}
.button:hover .add_button_cross, button:focus .add_button_cross {
  fill: rgb(255, 191, 28);
}

@media (min-width: 1280px) {
  .button_label {
    font-size: 20px;
  }
}`, "",{"version":3,"sources":["webpack://./src/styles/regular_btn.scss"],"names":[],"mappings":"AAAA;EACI,YAAA;EACA,YAAA;EACA,mBAAA;EACA,iDAAA;EACA,mBAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;AACJ;;AAEA;EACI,wBAAA;EACA,kBAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,cAAA;AACJ;;AAEA;EACI,gDAAA;AACJ;AAAI;EACE,wBAAA;AAEN;AAAI;EACE,aAAA;AAEN;AAAI;EACE,uBAAA;AAEN;;AAEE;EACE;IACI,eAAA;EACN;AACF","sourcesContent":[".button{\n    width: 290px;\n    height: 30px; \n    background: #222222;\n    box-shadow: 0px 0px 4px rgba(254, 250, 240, 0.25);\n    border-radius: 30px;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n}\n\n.button_label{\n    font-family: 'Fira Mono';\n    font-style: normal;\n    font-weight: 400;\n    font-size: 16px;\n    line-height: 19px;\n    color: #909090;\n    // color: black;\n}\n.button:hover, button:focus{\n    box-shadow: 0px 0px 4px rgba(255, 191, 28, 0.25);\n    .button_label{\n      color: rgb(255, 191, 28);\n    }\n    .add_button_cross path{\n      fill:inherit\n    }\n    .add_button_cross{\n      fill:rgb(255, 191, 28)\n    }\n  }\n\n  @media(min-width:1280px ) {\n    .button_label{\n        font-size: 20px;\n    }\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
