// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.menu_detaile_button_row {
  align-self: center;
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 15px;
}
.menu_detaile_button_row .button {
  width: 150px;
}

.delete_menu_button .button {
  background-color: #F15A5A;
  transition: 0.3s;
}
.delete_menu_button .button .button_label {
  color: black;
}
.delete_menu_button .button:hover {
  background-color: #cd4a4a;
}
.delete_menu_button .button:hover .button_label {
  color: #FFBF1C;
}

.menu_detaile_add_position_button .button {
  background-color: #7D62EA;
  transition: 0.3s;
}
.menu_detaile_add_position_button .button .button_label {
  color: black;
}
.menu_detaile_add_position_button .button:hover {
  background-color: #694be2;
}
.menu_detaile_add_position_button .button:hover .button_label {
  color: #FFBF1C;
}

@media (max-width: 769px) {
  .menu_detaile_button_row {
    width: 95%;
  }
}
@media (max-width: 550px) {
  .menu_detaile_button_row .button {
    height: 30px;
    width: 90px;
  }
}`, "",{"version":3,"sources":["webpack://./src/styles/menu_detaile_button_row.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;EACA,aAAA;EACA,8BAAA;EACA,WAAA;EACA,gBAAA;AACJ;AAAI;EACI,YAAA;AAER;;AAGI;EACI,yBAAA;EACA,gBAAA;AAAR;AACQ;EACI,YAAA;AACZ;AAEI;EACI,yBAAA;AAAR;AACQ;EACI,cAAA;AACZ;;AAMI;EACI,yBAAA;EACA,gBAAA;AAHR;AAIQ;EACI,YAAA;AAFZ;AAKI;EACI,yBAAA;AAHR;AAIQ;EACI,cAAA;AAFZ;;AAgBA;EACI;IACI,UAAA;EAbN;AACF;AAeA;EAEK;IACG,YAAA;IACA,WAAA;EAdN;AACF","sourcesContent":[".menu_detaile_button_row{\n    align-self: center;\n    display: flex;\n    justify-content: space-between;\n    width: 100%;\n    margin-top: 15px;\n    .button{\n        width: 150px;\n    }\n}\n\n.delete_menu_button{\n    .button{\n        background-color: #F15A5A;\n        transition: 0.3s;\n        .button_label{\n            color: black;\n        }\n    }\n    .button:hover{\n        background-color: #cd4a4a;\n        .button_label{\n            color:#FFBF1C;\n        }\n    }\n}\n\n.menu_detaile_add_position_button{\n  \n    .button{\n        background-color: #7D62EA;\n        transition: 0.3s;\n        .button_label{\n            color: black;\n        }\n    }\n    .button:hover{\n        background-color: #694be2;\n        .button_label{\n            color:#FFBF1C;\n        }\n    }\n}\n\n\n\n\n@media (min-width:1280px){\n\n}\n@media (max-width:1280px){\n    \n}\n@media (max-width:769px){\n    .menu_detaile_button_row{\n        width: 95%;\n    }\n}\n@media (max-width:550px){\n    .menu_detaile_button_row{\n     .button{\n        height: 30px;\n        width: 90px;\n        }\n    } \n\n    \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
